import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import styled from "styled-components";
import MobileBgPattern from "common/assets/image/faq/mobile-bg.svg";
import bgPattern from "common/assets/image/faq/faq-bg.svg";
import queryString from 'query-string';

const Section = styled.section`
  padding: 100px 0 135px;
  @media (max-width: 990px) {
    padding-top: 75px;
    padding-bottom: 90px;
    background-size: contain;
    background-repeat: repeat;
  }
  @media (max-width: 568px) {
    background-size: cover;
    padding-top: 45px;
    padding-bottom: 80px;
  }

  .container {
    padding: 100px 40px;
    background: #d1d9de url(${bgPattern}) no-repeat center;
    position: relative;
    background-size: cover;
    @media (max-width: 990px) {
      padding: 70px 0;
    }
    @media (max-width: 568px) {
      background: #d1d9de url(${MobileBgPattern}) no-repeat center;
      padding: 40px 0;
      background-size: cover;
    }
  }
`;

const FileDispute = ({ location }) => {
    React.useEffect(() => {
        const script = document.createElement("script");
        const parsed = queryString.parse(window.location.search);
        console.log(parsed);
        script.src =
            `https://api.miniextensions.com/v1/iframe-embed/nDqonedva795oKWRqhIu.js?absoluteShareUrl=https%3A%2F%2Fapp.miniextensions.com%2Fform%2FnDqonedva795oKWRqhIu`

        document.body.appendChild(script);
    }, []);

    return (
        <Layout location={location.pathname}>
            <Seo title="Audit Defense Program" />
            <Section>
                <Container>
                    <Heading as="h2" content={"Dispute Notice - Request For Arbitration"} />
                    <p></p>
                    <Text content={`Please use the form below to notify us of your dispute. We take
            disputes very seriously and consider this to be a significant customer
            service issue on our part.`} />
                    <div id="miniextensions-iframe-embed-nDqonedva795oKWRqhIu"></div>
                </Container>
            </Section>

        </Layout>
    );
};

export default FileDispute;
